
import { mapGetters, mapActions } from 'vuex'
import createEmptyCart from '~/apollo/mutation/emptyCart'

export default {
  name: 'PanelUser',
  data: () => {
    return {
      customer: '',
      loadingShow: false
    }
  },
  computed: {
    ...mapGetters({
      isLogged: 'login/GGlogged',
      GGcustomer: 'login/GGcustomer'
    })
  },
  watch: {
    isLogged: 'getNameUser'
  },
  mounted() {
    this.getNameUser()
  },
  methods: {
    ...mapActions({
      toggleisLogged: 'login/AAlogged',
      toggleModalLoginOpen: 'login/AAmodalOpen',
      AAactionLogin: 'login/AAactionLogin',
      toggleNoScroll: 'layout/AANoScroll',
      AAcartQTY: 'cart/AAcartQTY'
    }),
    getNameUser() {
      if (this.isLogged) {
        this.customer = this.GGcustomer
      }
      this.loadingShow = false
    },
    loginOpen(action) {
      this.toggleModalLoginOpen(true)
    },
    handleLogin() {
      this.toggleisLogged(true)
    },
    handleLogout() {
      this.loadingShow = true
      localStorage.removeItem('current_cart_token')
      localStorage.removeItem('current_customer_token')
      localStorage.removeItem('current_group_id')
      localStorage.removeItem('porteiroBF')
      this.toggleisLogged(false)
      this.AAcartQTY(0)
      location.reload(true)
      this.cartEmpty()
    },
    async cartEmpty() {
      await this.$apollo.mutate({
        mutation: createEmptyCart
      }).then((data) => {
        localStorage.setItem('current_cart_token', data.data.createEmptyCart)
        this.loadingShow = false
      })
    }
  }
}
